import React from "react"
import PropTypes from "prop-types"
import Layout from "../templates/Page"
import Header from "../components/ContentBuilder/Header"

BlogCategories.propTypes = {}

function BlogCategories(props) {
  return (
    <Layout hideHeader>
      <Header isLogin />
      <section className="section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col col-lg-10 mb-4 text-center">
              <h1>BLOG</h1>
            </div>
          </div>
          <div className="explore-functional row justify-content-center">
            <div className="col-xl-6 col-lg-10">
              <form className="search-box d-flex mb-5">
                <input
                  name="keyword"
                  className="filter-text"
                  placeholder="SEARCH"
                />
                <div className="filter-icon">
                  <img src="/img/explore/search.svg" />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="container my-4">
          <div className="row gutters-10">
            <div className="col-sm-6 col-lg-3">
              <div className="grid-item">
                <div className="grid-item-inner">
                  <img src="/img/related-articles-01.jpg" alt="" />
                  <h4 className="grid-title">Vestibulum gravida elementum</h4>
                  <a href="#" className="grid-caption related-articles-caption">
                    <div className="inner">
                      <div className="related-articles-category">Category</div>
                      <div className="related-articles-date">
                        10th November 2020
                      </div>
                    </div>
                  </a>
                  <button type="button" className="btn-like">
                    <svg width="30px" height="30px">
                      <title>Like</title>
                      <use xlinkHref="/img/icons.svg#icon-heart"></use>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="grid-item">
                <div className="grid-item-inner">
                  <img src="/img/related-articles-02.jpg" alt="" />
                  <h4 className="grid-title text-black">
                    Lorem ipsum dolor sit amet
                  </h4>
                  <a href="#" className="grid-caption related-articles-caption">
                    <div className="inner">
                      <div className="related-articles-category">Category</div>
                      <div className="related-articles-date">
                        10th November 2020
                      </div>
                    </div>
                  </a>
                  <button type="button" className="btn-like">
                    <svg width="30px" height="30px">
                      <title>Like</title>
                      <use xlinkHref="/img/icons.svg#icon-heart"></use>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="grid-item">
                <div className="grid-item-inner">
                  <img src="/img/related-articles-03.jpg" alt="" />
                  <h4 className="grid-title">
                    Vestibulum gravida elementum tempor
                  </h4>
                  <a href="#" className="grid-caption related-articles-caption">
                    <div className="inner">
                      <div className="related-articles-category">Category</div>
                      <div className="related-articles-date">
                        10th November 2020
                      </div>
                    </div>
                  </a>
                  <button type="button" className="btn-like">
                    <svg width="30px" height="30px">
                      <title>Like</title>
                      <use xlinkHref="/img/icons.svg#icon-heart"></use>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3">
              <div className="grid-item">
                <div className="grid-item-inner">
                  <img src="/img/related-articles-04.jpg" alt="" />
                  <h4 className="grid-title text-black">
                    Lorem ipsum dolor sit amet
                  </h4>
                  <a href="#" className="grid-caption related-articles-caption">
                    <div className="inner">
                      <div className="related-articles-category">Category</div>
                      <div className="related-articles-date">
                        10th November 2020
                      </div>
                    </div>
                  </a>
                  <button type="button" className="btn-like">
                    <svg width="30px" height="30px">
                      <title>Like</title>
                      <use xlinkHref="/img/icons.svg#icon-heart"></use>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BlogCategories
